<template>
    <div>
        <main class="main-content">
            <!--== Start Job Details Area Wrapper ==-->
            <section class="job-details-area">
            <div class="container">
                <div class="row">
                <div class="col-12">
                    <div class="job-details-wrap">
                    <div class="job-details-info">
                        <div class="thumb">
                        <img :src="modRes.pImage" width="130" height="130" alt="Image-HasTech" v-if="modRes.pImage">
                        <img src="../../assets/img/nopic.png" width="130" height="130" alt="Image-HasTech" v-if="!modRes.pImage">
                        </div>
                        <div class="content">
                        <h1 class="title">{{modRes.name}}</h1>
                        <div class="sub-title">
                            <el-rate v-model="modRes.score" disabled></el-rate>
                        </div>

                        </div>
                    </div>
                    <div class="job-details-price">
                        <button type="button" class="btn-theme" @click="opendownWindows(modRes.downloadspass,modRes.downloads)">立即下载</button>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-lg-7 col-xl-8">
                    <div class="job-details-item">
                    <div class="content">
                        <h4 class="title">介绍：</h4>
                        <div v-html="modRes.intro"></div>
                    </div>

                    <div class="content">
                        <a class="btn-apply-now" @click="opendownWindows(modRes.downloadspass,modRes.downloads)">免费下载 <i class="icofont-long-arrow-right"></i></a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-4">
                    <div class="job-sidebar">
                    <div class="widget-item">
                        <div class="widget-title">
                        <h3 class="title">Relevant Info</h3>
                        </div>
                        <div class="summery-info">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="table-name">素材编号</td>
                                <td class="dotted">:</td>
                                <td data-text-color="#03a84e">{{modRes.id}}</td>
                            </tr>
                            <tr>
                                <td class="table-name">素材分类</td>
                                <td class="dotted">:</td>
                                <td v-if="modRes.assets==1">办公文档－Word</td>
                                <td v-if="modRes.assets==2">数据表格－Excel</td>
                                <td v-if="modRes.assets==3">幻灯片－PPT</td>
                                <td v-if="modRes.assets==4">广告设计</td>
                                <td v-if="modRes.assets==5">资源分享区</td>
                            </tr>
                            <tr>
                                <td class="table-name">类目</td>
                                <td class="dotted">:</td>
                                <td>{{modRes.categories}}</td>
                            </tr>
                            <tr>
                                <td class="table-name">文件格式</td>
                                <td class="dotted">:</td>
                                <td>{{modRes.formats}}</td>
                            </tr>
                            <tr>
                                <td class="table-name">语言</td>
                                <td class="dotted">:</td>
                                <td>{{modRes.languages}}</td>
                            </tr>

                            <tr>
                                <td class="table-name">上传时间</td>
                                <td class="dotted">:</td>
                                <td data-text-color="#ff6000">{{modRes.upload.slice(0,10)}}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>

                    <div class="widget-item widget-tag">
                        <div class="widget-title">
                        <h3 class="title">标签:</h3>
                        </div>
                        
                        <div class="widget-tag-list" >
                        <router-link v-for="items in labers" :key="items" :to="{path:'/ModsLabels',query: {name:items}}" >    
                        {{items}}
                        </router-link>
                       
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <!--== End Job Details Area Wrapper ==-->
        </main>

    </div>
</template>

<script>
import {selectWordByPrimaryKey,setRecord,getUserOfAccnumber} from '../../api/index'
export default {
    data(){
        return{
            id:'',
            modRes:[],
            labers:[],
            userConts:[]
        }
    },
    created(){
        var id = this.$route.query.id;
        this.id=id
        this.getContent()
        this.selUsers()
    },
    methods:{
        opendownWindows(pass,urls){
            this.$confirm('即将跳转外部进行下载操作, 下载密码：'+pass+'', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
            }).then(() => {
                if(window.sessionStorage.getItem("name")){  
                    console.log(this.modRes,"333");                 
                    let params = new URLSearchParams()        
                    params.append('downuser', this.userConts.accnumber)   
                    params.append('wname', this.modRes.name)
                    params.append('wid', this.modRes.id)   
                    params.append('assects', this.modRes.assets)              
                    setRecord(params)
                        .then(res => {
                        if (res.code === 1) {
                            //this.registerForm = {}
                            window.open(urls)
                        }else {
                            this.$alert('系统错误，请稍后再试！', '错误', {
                                cancelButtonText: '确定',
                                type: 'error',
                                
                            });
                        }
                        })
                        .catch(err => {
                        console.log(err)
                        }) 
                }else{
                    this.$confirm('登录后才能允许下载哦！是否前往登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                        }).then(() => {
                            this.$router.push( `/login`)
                        }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '想要下载先登录，积累下载积分还能升级您的账号哦！'
                        });          
                    },10000);
                }
        
            
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消下载'
            });
            });
     
        },
        async getContent(){
          let res= await selectWordByPrimaryKey(this.id)
          this.modRes=res
          this.uplodes=res.uplodes
          this.labers=res.labelsid.split(',')
        },
        async selUsers() {
            this.userConts= await getUserOfAccnumber(window.sessionStorage.getItem("name"));     
        },
    }
}
</script>
<style>

 .downpass{
     margin-bottom: 10px;
 }
</style>